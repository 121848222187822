import React, { useState } from 'react';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { ReferFriend } from '../xcarepoints/ReferFriend';
import { useHistory, useLocation } from 'react-router-dom';

export const Referral = () => {
    const [isShowReferFriend, setIsShowReferFriend] = useState(false);
    return (
        <>

            <div className="card_item staticad card p-3">
                <div className="infocard_header mb-2">
                    <div className="card_ititle">Give $50. Get $50. </div>
                    <div className="card_desc">
                        {/* When you refer your friend. */}
                        <label className="text-dark opacity-75">Coming soon...</label>
                    </div>
                </div>

                <div className="card_ilink">
                    {/* <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowReferFriend(true) }}>Manage Referrals</button> */}
                </div>

            </div>


            <SharedOffCanvas
                isOpen={isShowReferFriend}
                handleClose={() => { setIsShowReferFriend(false) }}
                Children={ReferFriend}
                handleSuccess={() => {
                    setIsShowReferFriend(false)
                }}
            />
        </>
    )
}
